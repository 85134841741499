import { HStack, VStack, Text, Pressable, Input, Box, Image, Divider, Button, Modal, AddIcon, FormControl, TextArea, ChevronRightIcon, ChevronLeftIcon, CloseIcon, } from 'native-base';
import React, { useRef, useState } from 'react';
import Image1 from '../../Platform/AVImage';
import AVVideo from '../../Platform/AVVideo';
import { Link } from 'react-router-dom';
import { Link as AVlink } from '../../AVCore/Link';
import AVCancel from 'components/common/AVCancel';
import EditPostpopup from '../../Screens/Home/EditPostpopup';
import AVImageSlider from 'components/common/AVImageSlider';
import { ScrollView } from 'react-native-gesture-handler';
import { Media } from '../../AVCore/Media';
import report from '../../assets/images/report.png';
import dislike from '../../assets/images/dislike.svg';
import startruck from '../../assets/images/startruck.svg';
import sads from '../../assets/images/sads.svg';
import fan from '../../assets/images/fan.svg';
import like from '../../assets/images/like.svg';
import Moment from 'react-moment';
import ProfilePic from '../common/ProfilePicture';
import { Person } from '../../AVCore/AVCore';
import { useSelector, RootStateOrAny } from 'react-redux';
import AVAdd from 'components/common/AVAdd';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MoreHorizontalIcon, LikeIcon, ReplyIcon, ChatIcon, CommentIcon, AddPhotoIcon, HappyIcon, PaperPlaneIcon, DeleteIcon, EditIcon, VideoIcon } from '../../assets/icons/icon-assets';
import { Loc } from '../../App/Loc';
import Picker from 'emoji-picker-react';
import AVEmojiPicker from '../../components/common/AVEmojiPicker';
import FilePicker from '../../Platform/FilePicker';
import useFillFeeds from '../../Hooks/useFillFeeds';
import { AHStack, AVBox, AVPressable, AVStack } from '../../components/ui';
import SvgIcon from 'assets/icons';
import * as AV from 'AVCore/AVCore';
import fallBackImage from '../../assets/images/avatar.png';
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import { Dimensions } from 'react-native';
import heart from '../../assets/images/heart.png';
import broken from '../../assets/images/broken.png';
import AVlinktext from '../common/AVlinktext'
import settings from '../../Platform/settings.json';
import Tooltip from '@mui/material/Tooltip';
import AVConverttime from './AVConverttime';
function Comment ( props : any )
{

    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const [ videos, setvideos ] = React.useState<any>( [] );
    const [ picker, setPicker ] = React.useState( false );
    const [ isImageProcessing, setIsImageProcessing ] = React.useState( false );
    const [ noticeMessage, setNoticeMessage ] = React.useState<any>( '' );
    const [ showAddMedia, setShowAddMedia ] = React.useState( false );
    const dimension = Dimensions.get( 'window' );
    const isMobile = dimension?.width <= 600 || false;
    const [ showFeedbackImpressions, setShowFeedbackImpressions ] = React.useState( false );
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const [ shiftPressed, setShiftPressed ] = useState( false );

    const handleKeyPress = ( e ) =>
    {
        const { key } = e.nativeEvent;
        if ( key === 'Shift' )
        {
            setShiftPressed( true );
        } else if ( key === 'Enter' && !shiftPressed && !isMobile )
        {
            e.preventDefault();
            handlePostComment();
        } else
        {
            setShiftPressed( false );
        }
    };
    const EditPostMenu = ( { item, showEditPopup, handleEditPost, currentUser, handleDeletePost, handleReportPost, } ) =>
    {

        const menuRefs = useRef( null );
        useOutsideAlerter( menuRefs );
        function useOutsideAlerter ( ref )
        {
            React.useEffect( () =>
            {
                function handleClickOutside ( event )
                {
                    if ( ref.current && !ref.current.contains( event.target ) )
                    {
                        setShowEditPopup( false );
                    }
                }
                document.addEventListener( 'mousedown', handleClickOutside );
                return () =>
                {
                    document.removeEventListener( 'mousedown', handleClickOutside );
                };
            }, [ ref ] );
        }
        return (
            <>
                { showEditPopup && (
                    <VStack
                        ref={ menuRefs }
                        position={ 'absolute' }
                        width='125px'
                        bgColor='#fff'
                        borderColor='coolGray.200'
                        borderWidth='1'
                        shadow={ 1 }
                        zIndex={ 1 }
                        top={ '30px' }
                        right={ 1 }
                        borderRadius={ 5 }
                    >
                        { item?.person?.id !== currentUser?.id && ( <>
                            <Pressable
                                onPress={ () =>
                                {
                                    handleReportPost( item );
                                } }
                                p={ 2 }
                                _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                            >
                                <HStack alignItems={ 'center' } space={ 2 }>
                                    <Image source={ report } alt='report' size='15' />
                                    <Text>Report</Text>
                                </HStack>
                            </Pressable>
                        </> ) }
                        { item?.person?.id === currentUser?.id && ( <>
                            <Pressable
                                onPress={ () =>
                                {
                                    props?.handleEditPost( item );
                                } }
                                p={ 2 }
                                _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                            >
                                <HStack alignItems={ 'center' } space={ 2 }>
                                    <EditIcon size={ '18px' } color={ 'AVColor.secondaryText' } />
                                    <Text>{ Loc.currentLang.menu.edit }</Text>
                                </HStack>
                            </Pressable>
                            <Divider thickness={ '0.5' } />
                            <Pressable
                                onPress={ () =>
                                {
                                    props?.handleDeleteComment(item);
                                } }
                                p={ 2 }
                                _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                            >
                                <HStack alignItems={ 'center' } space={ 2 }>
                                    <DeleteIcon size={ '18px' } color={ 'AVColor.secondaryText' } />
                                    <Text>{ Loc.currentLang.menu.delete }</Text>
                                </HStack>
                            </Pressable>
                        </> ) }
                    </VStack>
                ) }
            </>
        );
    };

    const {
        item,
        setItem,
        isAddComment,
        commentValue,
        showFilePicker,
        handlePostComment,
        handleLikePost,
        showEmoji,
        setShowEmoji,
        handleFilePicker,
        getImageGalleryFromFilePickerCreateConvo,
        handleComment,
        showFBEmoji,
        setShowFBEmoji,
        showComment,
        setShowComment,
        handleConvoPostComment,
        handleEditPopup,
        setShowEditPopup,
        handleReplyPost,
        showEditPopup,
        handleDeletePost,
        handleEditPost,
        handleReportPost,
        showEditPost,
        showModal,
        setShowModal,
        images,
        setImages,
        isDeleted,
        setShowEditPost,
        handleAddComment,
        textEditRef,
        setIsCommentLoading,
        isCommentLoading,
        handleEditComment,
        handleUpdatePost,
        editComment,
        SetType,
        setParentId,
        showEditFilePicker,
        media,
        setCommentMedia,
        setCommentTo,
        handleEditFilePicker,
        onEmojiClick,
        handleremoveLike,
        cmdParent, setCmdParent
    } = useFillFeeds( props, 'feedComments' );

    const [ position, setPosition ] = React.useState<any>( 'top' );
    const commentValueRef = useRef<HTMLInputElement>( null );
    const [ commentItem, setCommentItem ] = React.useState( item );
    const imageInputRef = React.useRef<HTMLInputElement>( null );
    const tooltipProps = {
        hasArrow: true,
        arrowSize: 15,
        offset: true,
        openDelay: 500,
        color: 'white', // Text color of the tooltip
        fontSize: 'md', // Font size of the tooltip text
        px: 4, // Horizontal padding of the tooltip
        py: 2, // Vertical padding of the tooltip
        _text: {
            textAlign: 'center', // Text alignment within the tooltip
        },
        cursor: 'default',
        zIndex: 999,// Remove the pointer cursor
    };
    const [ commentValues, setCommentValue ] = useState('')

    const handleImages = async ( e : any ) =>
    {
        try
        {
            setIsCommentLoading( true );
            let files : any = Array.from( e?.target?.files );
            if ( files.length > 20 )
            {
                return;
            }
            setShowAddMedia( false );
            setPicker( false );
            files.forEach( ( img : any ) =>
            {
                if (
                    img.type !== 'image/jpeg' &&
                    img.type !== 'image/png' &&
                    img.type !== 'image/jfif' &&
                    img.type !== 'image/webp' &&
                    img.type !== 'image/gif' &&
                    img.type !== 'video/mp4'
                )
                {
                    files = files.filter( ( item : any ) => item.name !== img.name );
                    return;
                } else
                {
                    const reader = new FileReader();
                    reader.readAsDataURL( img );
                    reader.onload = ( readerEvent : any ) =>
                    {
                        if ( img.type.startsWith( 'image' ) )
                            setImages( ( images ) => [ ...images, readerEvent.target.result ] );
                        else setvideos( ( videos ) => [ ...videos, readerEvent.target.result ] );
                    };
                }
            } );
            setIsImageProcessing( true );
            setNoticeMessage( 'Please wait while we process your images...' );
            await AV.Media.saveFiles( AV.Media.getFormData( files ), currentUser?.region ).then( ( data ) =>
            {
                setCommentMedia( ( prev ) => [ ...prev, ...data ] );
                setIsCommentLoading( false );
            } );
            setNoticeMessage( null );
            setIsImageProcessing( false );
        } catch {
            setIsCommentLoading( false );
        }
    };

    const handleClick = () =>
    {
        if ( commentValueRef && commentValueRef.current )
        {
            commentValueRef.current.focus();
        }
    };
    const [ imageUrls, setImagesUrl ] = React.useState( [] );
    const [ videoUrls, setVideoUrl ] = React.useState( [] );
    const [ showDetails, setShowDetails ] = React.useState( false );

    const showVIDEODetails = async ( item : any ) =>
    {
        setShowDetails( true );
        if (
            ( item?.galleryPost && item?.galleryPost?.length > 0 ) ||
            ( item?.media?.storeId && item?.media?.type?.startsWith( 'video' ) )
        )
        {
            for ( let i = 0; i < item?.galleryPost.length; i++ )
            {
                media.push( item?.galleryPost[ i ].media );
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'video' ) )
            {
                media.push( item?.media );
                setVideoUrl( media );
            }
        }
    };

    React.useEffect( () =>
    {
        if (
            ( item?.galleryPost && item?.galleryPost?.length > 0 ) ||
            ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
        )
        {
            for ( let i = 0; i < item?.galleryPost?.length; i++ )
            {
                media.push( item?.galleryPost[ i ].media );
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
            {
                media.push( item?.media );
            }
            let images = media.length && media.map( ( i ) => AV.Media.getURL( i ) );
            setImagesUrl( images );
        }
    }, [] );

    const menuRef = useRef( null );
    useOutsideAlerter( menuRef );
    //Hook that alerts clicks outside of the passed ref
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            // Alert if clicked on outside of element
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
               
                }
            }
            // Bind the event listener
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                // Unbind the event listener on clean up
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    const navigate = useNavigate();
    return (
        <>
            { showDetails && (
                <Box
                    position={ 'fixed' }
                    top={ 0 }
                    bottom={ 0 }
                    left={ 0 }
                    right={ 0 }
                    justifyContent={ 'space-between' }
                    backgroundColor={ 'AVColor.overlay' }
                    width={ '100%' }
                    zIndex={ 9999 }
                >
                    <div
                        className={ `show_post_details` }
                        style={ {
                            maxWidth: '950px',
                        } }
                    >
                        <ScrollView>
                            <VStack padding={ 3 } justifyContent={ 'space-between' }>
                                <HStack justifyContent={ 'flex-end' }>
                                    <AVCancel size={ undefined } color={ undefined } callback={ () => setShowDetails( false ) } />
                                </HStack>
                                <HStack space={ 3 }>
                                    <VStack
                                        flex={ 3 }
                                        padding={ 2.5 }
                                        alignItems={ 'flex-start' }
                                        justifyContent={ 'flex-start' }
                                        backgroundColor={ '#000' }
                                        borderRadius={ 3 }
                                    >
                                        <div style={ { width: '100%', height: '100%' } }>
                                            { videoUrls.length === 0 && <AVImageSlider setCommentValue={ setCommentValue } slideItems={ imageUrls } previewitem={ undefined } setpreviewitem={ undefined } type={ undefined } settype={ undefined } fullscreen={ undefined } setIsFullScreen={ undefined }  setShowDetails={ undefined } setIscommentItem={ undefined } setIsCommentSlider={ undefined } isCommentSlider={ undefined } handleslideChange={ undefined } isHeader={ undefined } setHeader={ undefined } setShowPreview={ undefined } /> }
                                            { videoUrls.length > 0 && <AVImageSlider setCommentValue={ setCommentValue } slideItems={ videoUrls } previewitem={ undefined } setpreviewitem={ undefined }  type={ undefined } settype={ undefined } fullscreen={ undefined } setIsFullScreen={ undefined } setShowDetails={ undefined } setIscommentItem={ undefined } setIsCommentSlider={ undefined } isCommentSlider={ undefined } handleslideChange={ undefined } isHeader={ undefined } setHeader={ undefined } setShowPreview={ undefined } /> }
                                        </div>
                                    </VStack>
                                    <VStack flex={ 1.5 }>
                                        <PostHeader
                                            item={ item }
                                            setItem={ setItem }
                                            handleEditPopup={ handleEditPopup }
                                            showEditPopup={ showEditPopup }
                                            handleDeletePost={ handleDeletePost }
                                            handleEditPost={ handleEditPost }
                                            menuRef={ menuRef }
                                            handleremoveLike={ handleremoveLike }
                                            showModal={ showModal }
                                            setShowModal={ setShowModal }
                                            currentUser={ currentUser }
                                            handleDeleteComment={ props?.handleDeleteComment }
                                            handleLikePost={ handleLikePost } />
                                        <Box maxH={ '100px' } overflow={ 'hidden' }>
                                            <Text>{ item?.text }</Text>
                                        </Box>
                                        <Divider mt={ 1 } />
                                        <HStack>
                                            <PostActions
                                                item={ item }
                                                handleLikePost={ handleLikePost }
                                                handleReplyPost={ handleReplyPost }
                                            />
                                        </HStack>
                                        <Divider />
                                        <Box ref={ menuRef } overflow={ 'hidden' } overflowY={ 'scroll' } height={ '450px' }>
                                            { item?.children?.map( ( subitem ) => (
                                                <Comment subitem={ subitem } data={ props } key={ subitem?.id } handleEditPost={ props?.handleEditPost } />
                                            ) ) }
                                        </Box>
                                    </VStack>
                                </HStack>
                            </VStack>
                        </ScrollView>
                    </div>
                </Box>
            ) }

            <VStack paddingLeft={ 6 } width={ '100%' } justifyContent={ 'space-between' }>
                <Box key={ item?.id } paddingX={ '12px' }>
                    <VStack width={ '100%' } alignItems='flex-start'>

                        <HStack width={ '100%' } space={ 2 } alignSelf='flex-start' marginRight={ 4 }>
                            <Pressable
                                onPress={ () =>
                                    navigate( `/profile/${ item?.person.id }/${ item?.person?.region }`, { state: { user: item.person } } )
                                }
                            >
                                <HStack>
                                    <ProfilePic width={ 38 } height={ 38 } link={
                                        currentUser?.id == item?.person?.id ?
                                            AV.AVEntity.getLink( currentUser ) :
                                        entitydatalist?.find( ( x : any ) => x?.id == item?.person?.id ) ?
                                        AV.AVEntity.getLink( entitydatalist?.find( ( x : any ) => x?.id == item?.person?.id ) ) : item?.person } />
                                </HStack>
                            </Pressable>
                            <VStack
                                flex={ 1 }
                                bgColor={ 'AVColor.hovercolor2' }
                                p={ 1.5 }
                                px={ 2 }
                                alignSelf={ 'flex-start' }
                                borderRightRadius={ 10 }
                                borderBottomRadius={ 10 }
                            >
                                <HStack justifyContent={ 'space-between' }>
                                    <Tooltip title={ item?.person?.title } PopperProps={ {
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                px: 2, // padding x-axis (left and right)
                                                width: 150,                                               
                                                textAlign: 'center', // Center align the text
                                                fontSize: '12px',
                                            },
                                        },
                                    } }>
                                        <Pressable width={ '90%' }
                                            onPress={ () =>
                                                navigate( `/profile/${ item?.person.id }/${ item?.person?.region }`, { state: { user: item.person } } )
                                            }
                                        >
                                            <Text fontSize={ '16px' } fontWeight={ '500' } color={ 'AVColor.primaryText' } text-overflow={ 'ellipsis' } numberOfLines={ 1 } >
                                                
                                                { currentUser?.id == item?.person?.id ? currentUser?.name :
                                                    entitydatalist?.find( ( x : any ) => x?.id == item?.person?.id ) ?
                                                    entitydatalist?.find( ( x : any ) => x?.id == item?.person?.id ).name
                                                    : item?.person?.title }
                                            </Text>
                        <Text fontSize={ [ 10, 12 ] }>
                          <AVConverttime date={ item?.createDate } page={ 'Post' } />
                        </Text>
                                        </Pressable>
                                    </Tooltip>
                                    <AVStack alignItems={ 'flex-start' } justifyContent={ 'flex-start' }>
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                handleEditPopup( item );
                                            } }
                                            _hover={ { backgroundColor: '#D3D3D3', rounded: 'full' } }
                                            p={ 2 }
                                        >
                                            <MoreHorizontalIcon size={ '18px' } color={ 'AVColor.primaryText' } />
                                        </Pressable>
                    </AVStack>

                  </HStack>
                  <HStack top={'3px' }>
                                <Text
                                    fontSize={ '15px' }
                                    color={ 'AVColor.secondaryText' }
                                    numberOfLines={ 5 }
                                    lineHeight={ '25' }
                                >
                                    <AVlinktext text={ item?.text } />
                    </Text>
                  </HStack>
                                <Box borderRadius={ 5 } overflow={ 'hidden' } maxH={'300px'}>
                                    { ( ( item?.media || item?.galleryPost?.length >= 1 ) ) ? (
                                        <Pressable
                                            width={ '40%' }
                                            alignSelf={ 'center' }
                                            onPress={ () =>
                                            {
                                                props.showFeedDetails( props?.subitem );
                                                props.setIscommentItem( props?.subitem )
                                                props?.setIsFullScreen( true );
                                                props?.setIsCommentSlider( true );
                                            } }
                                        >
                                            <LoadMedia item={ item } showdetail={ props?.showDetails } />
                                        </Pressable> ) : ( <LoadMedia item={ item } showdetail={ props?.showDetails } /> ) }
                                    { item?.media?.type?.startsWith( 'video' ) && (
                                        <div >
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    showVIDEODetails( item );
                                                } }
                                            >
                                                {/*<ChevronRightIcon />*/ }
                                            </Pressable>
                                        </div>
                                    ) }
                                    { item?.media?.type?.startsWith( 'video' ) && (
                                        <div >
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    showVIDEODetails( item );
                                                } }
                                            >
                                                {/*<ChevronLeftIcon />*/ }
                                            </Pressable>
                                        </div>
                                    ) }
                                </Box>
                            </VStack>

                        </HStack>

                        <EditPostMenu item={ item } showEditPopup={ showEditPopup } currentUser={ currentUser } handleEditPost={ handleEditPost } handleDeletePost={ handleDeletePost } handleReportPost={ handleReportPost } />
                        {/* like reply            */ }

                        <AHStack
                            gap={ 3 }
                            alignItems={ 'center' }
                            width={ '100%' }
                            justifyContent={ 'space-between' }
                            space={ 20 }
                            paddingHorizontal={ 10 }
                        >
                            <HStack pl={ 2 } mt={ 1 } left={ 10 } alignItems={ 'center' }>
                                { item?.impressions?.filter( ( x ) => x?.id == currentUser?.id && x?.impression == 'Like' ).length >
                                    0 ?
                                    ( <Pressable
                                        onPress={ () =>
                                        {
                                            handleremoveLike( item, 'Like' );
                                        } }
                                        p={ 1.5 }
                                        px={ 2 }
                                        _hover={ { backgroundColor: 'AVColor.hovercolor2', borderRadius: 5 } }
                                    >
                                        <HStack alignItems={ 'center' } space={ 1 }>
                                            <LikeIcon size={ '18px' } color='#32AE78' />
                                            <Text color='#32AE78' fontSize={ '15px' } mt={ 1 }>
                                                { Loc.currentLang.menu.like }
                                            </Text>
                                        </HStack>
                                    </Pressable> ) :
                                    ( <Pressable
                                        onPress={ () => handleLikePost( item, 'Like' ) }
                                        p={ 1.5 }
                                        px={ 2 }
                                        _hover={ { backgroundColor: 'AVColor.hovercolor2', borderRadius: 5 } }
                                    >
                                        <HStack alignItems={ 'center' } space={ 1 }>
                                            <LikeIcon size={ '18px' } color='AVColor.secondaryText' />
                                            <Text color='AVColor.secondaryText' fontSize={ '15px' } mt={ 1 }>
                                                { Loc.currentLang.menu.like }
                                            </Text>
                                        </HStack>
                                    </Pressable> ) }
                                <Box>
                                    <Pressable
                                        onPress={ () =>
                                        {
                                            setShowFeedbackImpressions( !showFeedbackImpressions );
                                        } }
                                        p={ 1.5 }
                                        px={ 2 }
                                        _hover={ { backgroundColor: 'AVColor.hovercolor2', borderRadius: 5 } }
                                    >
                                        <AHStack alignItems='center' gap={ '5px' }>
                                            <ChatIcon size={ '18px' } color={ item?.impressions?.filter( ( x ) => x?.id == currentUser?.id && x?.impression !== 'Like').length > 0 ? '#32AE78' : 'AVColor.secondaryText' } />
                                            <Text color={ item?.impressions?.filter( ( x ) => x?.id == currentUser?.id && x?.impression !== 'Like').length > 0 ? '#32AE78' : 'AVColor.secondaryText' } fontSize={ '15px' } mt={ 1 }>
                                                { Loc.currentLang.menu.feedBack }
                                            </Text>
                                        </AHStack>
                                    </Pressable>
                                    { showFeedbackImpressions && (
                                        <HStack
                                            space={ 2 }
                                            backgroundColor={ 'gray.100' }
                                            ref={ menuRef }
                                            position={ 'absolute' }
                                            top={ '-40px' }
                                            alignItems={ 'center' }
                                            justifyContent={ 'space-around' }
                                            px={ 2 }
                                            py={ 1 }
                                            borderRadius={ 'full' }
                                        >
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Like' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Like"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.like } } alt='like' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>

                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Unlike' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Unlike"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.unlike } } alt='unlike' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>

                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'heart' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Heart"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.heart } } alt='heart' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Wow' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Wow"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.wow } } alt='startruck' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>

                                            
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Lol' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Lol"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.lol }} alt='Lol' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>


                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Cheer' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Cheer"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.cheer } } alt='fan' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>

                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Question' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Question"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.question } } alt='Question' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>

                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'broken' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Broken"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.broken } } alt='broken' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Sad' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Sad"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.sad } } alt='sad' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>

                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleLikePost( item, 'Mad' );
                                                    setShowFeedbackImpressions( false );
                                                } }
                                            >
                                                <HStack>
                                                    <Tooltip
                                                        title="Mad"
                                                        enterDelay={ 100 }
                                                        leaveDelay={ 0 }
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Image source={ { uri: settings.mad } } alt='Mad' size='20px' />
                                                    </Tooltip>
                                                </HStack>
                                            </Pressable>
                                            
                                        </HStack>
                                    ) }
                                </Box>
                                { ( item?.allowReply == true || currentUser?.id === item?.person?.id ) &&
                                    ( <Pressable
                                        onPress={ () =>
                                        {
                                            handleReplyPost( item );
                                            SetType( 'ReplyComment' );
                                            setParentId( item.id );
                                            setCmdParent( item )
                                            handleClick();
                                        } }
                                        p={ 1.5 }
                                        px={ 2 }
                                        _hover={ { backgroundColor: 'AVColor.hovercolor2', borderRadius: 5 } }
                                    >
                                        <HStack alignItems={ 'center' } space={ 1 }>
                                            <ReplyIcon size={ '18px' } color='AVColor.secondaryText' />
                                            <Text color='AVColor.secondaryText' fontSize={ '15px' } mt={ 1 }>
                                                { Loc.currentLang.menu.reply }
                                            </Text>
                                        </HStack>
                                    </Pressable> ) }
                            </HStack>
                            <HStack bottom={ isMobile && props?.showDetails ? '-17px' : 2 } alignSelf={ 'flex-end' }>
                                {/*{ item?.impressions?.filter((x) => x.type == 'Like').length > 0 && (*/ }
                                {/*    <AHStack*/ }
                                {/*        gap={ 3 }*/ }
                                {/*        borderRadius={'20px'}*/ }
                                {/*        alignSelf={ 'flex-end' }*/ }
                                {/*        backgroundColor={ '#def2ff' }*/ }
                                {/*        paddingHorizontal={ '5px' }*/ }
                                {/*        paddingVertical={'3px'}*/ }
                                {/*    >*/ }
                                {/*        <Image source={ like } alt='Like' size='18' />*/ }
                                {/*        <Text fontWeight={ 'bold' } fontSize={ '11' }>*/ }
                                {/*            { item?.impressions?.filter((x) => x.type == 'Like').length }*/ }
                                {/*        </Text>*/ }
                                {/*    </AHStack>*/ }
                                {/*)}*/ }
                                <AHStack gap={ 3 } justifyContent={ 'flex-end' } flex={ 1 } paddingLeft={ 5 }>
                                    { item?.impressions?.filter( ( x ) => x.impression == 'Wow' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.wow } } alt='startruck' size='18' />
                                            <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Wow' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }
                                   
                                    { item?.impressions?.filter( ( x ) => x.impression == 'Like' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.like } } alt='Like' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Like' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }

                                    { item?.impressions?.filter( ( x ) => x.impression == 'Unlike' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.unlike } } alt='Like' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Unlike' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }
                                    { item?.impressions?.filter( ( x ) => x.impression == 'Cheer' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.cheer } } alt='sad' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Cheer' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }
                                    
                                    { item?.impressions?.filter( ( x ) => x.impression == 'Lol' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.lol } } alt='sad' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Lol' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }

                                    { item?.impressions?.filter( ( x ) => x.impression == 'Question' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.question } } alt='sad' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Question' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }
                                    { item?.impressions?.filter( ( x ) => x.impression == 'Sad' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.sad } } alt='sads' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Sad' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }

                                    { item?.impressions?.filter( ( x ) => x.impression == 'Mad' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.mad } } alt='sads' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'Mad' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }
                                    { item?.impressions?.filter( ( x ) => x.impression == 'heart' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.heart } } alt='sad' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'heart' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }
                                    { item?.impressions?.filter( ( x ) => x.impression == 'broken' ).length > 0 && (
                                        <AHStack
                                            gap={ 3 }
                                            borderRadius={ '20px' }
                                            alignItems={ 'center' }
                                            backgroundColor={ '#def2ff' }
                                            paddingHorizontal={ '5px' }
                                            paddingVertical={ '2px' }
                                        >
                                            <Image source={ { uri: settings.broken } } alt='sad' size='18' />
                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                { item?.impressions?.filter( ( x ) => x.impression == 'broken' ).length }
                                            </Text>
                                        </AHStack>
                                    ) }
                                </AHStack>
                            </HStack>
                        </AHStack>
                        { isAddComment && (
                            <>
                                <VStack flex={ 1 } width={ '100%' }>

                                    <HStack alignItems='center' px={ 3 } flex={ 1 } space={ 2 } >
                                        <Pressable
                                            onPress={ () =>
                                                navigate( `/profile/${ currentUser?.id }/${ currentUser?.region }`, { state: { user: currentUser } } )
                                            }
                                        >
                                            <ProfilePic width={ 35 } height={ 35 } link={ Person.getFk( Person.self() ) } />
                                        </Pressable>
                                        <HStack flex={ 1 } marginTop={ 1 }>
                                            { showEmoji && (
                                                <Box position={ 'absolute' } bottom={ '60px' } right={ '50px' }>
                                                    {/* <Picker onEmojiClick={ onEmojiClick } />*/ }
                                                    <AVEmojiPicker onEmojiSelect={ ( e ) => { return onEmojiClick( e ); } } />
                                                </Box>
                                            ) }
                                            <HStack flex={ 1 } space={ 1 } my={ 2 } width={ '100%' }>
                                                <HStack flex={ 2 } borderRadius={ 'full' } bgColor={ 'AVColor.inputBg' }>
                                                    <Input
                                                        ref={ commentValueRef }
                                                        width={ 'full' }
                                                        size='xl'
                                                        autoFocus={ true }
                                                        outlineColor={ 'transparent' }
                                                        _focus={ { bgColor: 'AVColor.inputBg', borderRadius: 'full' } }
                                                        focusOutlineColor={ 'transparent' }
                                                        value={ commentValue }
                                                        placeholder={ Loc.currentLang.placeholderText.commentPlaceholder }
                                                        borderRadius={ 0 }
                                                        borderWidth={ 0 }
                                                        color={ 'black' }
                                                        placeholderTextColor={ 'gray.500' }
                                                        onKeyPress={ handleKeyPress }
                                                        onChangeText={ ( e ) => handleAddComment( e ) }
                                                    />
                                                </HStack>
                                                <HStack alignItems={ 'center' }>
                                                    { ( commentValue?.trim()?.length > 0 || showFilePicker || showEmoji ) && (
                                                        <Button
                                                            bgColor={ 'none' }
                                                            isLoading={ isCommentLoading }
                                                            _loading={ { bgColor: 'AVColor.primary' } }
                                                            onPress={ handlePostComment }
                                                        >
                                                            <PaperPlaneIcon color={ 'AVColor.primary' } size={ '25px' } />
                                                        </Button>
                                                    ) }
                                                    <Pressable
                                                        onPress={ () =>
                                                        {
                                                            setShowEmoji( !showEmoji );
                                                        } }
                                                        mx={ 2 }
                                                    >
                                                        <SvgIcon name='HAPPY' size={ 20 } />
                                                    </Pressable>
                                                    <Pressable onPress={ handleFilePicker }>
                                                        <SvgIcon name={ 'ADD_PHOTO' } size={ 20 } />
                                                    </Pressable>
                                                </HStack>
                                            </HStack>
                                        </HStack>
                                    </HStack>

                                    <VStack justifyContent={ 'center' } alignItems={ 'center' }>
                                        { showFilePicker && (
                                            <Box
                                                minHeight={ 220 }
                                                height={ 'auto' }
                                                borderWidth='1'
                                                rounded='sm'
                                                borderStyle={ 'dashed' }
                                                width={ '90%' }
                                                marginTop={ 2 }
                                                marginLeft={ 6 }
                                                justifyContent={ 'center' }
                                            >
                                                <div className='overflow_a scrollbar'>
                                                    <input
                                                        type='file'
                                                        accept='image/jpeg,image/png,image/jfif,image/webp,image/gif,video/mp4,'
                                                        multiple
                                                        hidden
                                                        ref={ imageInputRef }
                                                        onChange={ handleImages }
                                                    />
                                                    <HStack my={ 3 } mx={ 5 } space={ 4 } alignItems={ 'center' } zIndex={ 0 }>
                                                        { (images.length > 0) || (videos.length > 0) ? (
                                                            <RenderImages
                                                                images={ images }
                                                                imageInputRef={ imageInputRef }
                                                                setImages={ setImages }
                                                                setvideos={ setvideos }
                                                                videos={ videos }
                                                            />
                                                        ) : (
                                                            <Box
                                                                borderWidth={ 1 }
                                                                borderRadius={ 5 }
                                                                overflow={ 'hidden' }
                                                                borderColor={ 'avcolors.black' }
                                                                borderStyle={ 'none' }
                                                                maxH={ '300px' }
                                                                height='100%'
                                                                width='100%'
                                                                overflowY={ 'auto' }
                                                            >
                                                                <Pressable onPress={ () => imageInputRef?.current?.click() }>
                                                                    <VStack
                                                                        alignItems={ 'center' }
                                                                        justifyContent={ 'center' }
                                                                        minH={ '150px' }
                                                                        space={ 2 }
                                                                    >
                                                                        <Box backgroundColor={ 'AVColor.grey' } p={ 2 } borderRadius='full'>
                                                                            <AddIcon color={ 'AVColor.secondary' } />
                                                                        </Box>
                                                                        <Text fontSize={ 14 } color={ 'AVColor.grey' }>
                                                                            { Loc.currentLang.menu.imageOrVideo }
                                                                        </Text>
                                                                    </VStack>
                                                                </Pressable>
                                                            </Box>
                                                        ) }
                                                    </HStack>
                                                </div>
                                            </Box>
                                        ) }
                                    </VStack>
                                </VStack>
                            </>
                        ) }
                    </VStack>
                </Box>
                { props.type == 'feed' ? (
                    item?.children?.slice( 0, 0 ).map( ( subitem ) => (
                        <Comment subitem={ subitem } data={ props } key={ subitem?.id } handleEditPost={ props?.handleEditPost } />
                    ) )

                ) : (
                    item?.children?.map( ( subitem ) => (
                        <Comment subitem={ subitem } data={ props } key={ subitem?.id } handleEditPost={ props?.handleEditPost } />
                    ) )
                ) }
            </VStack>
            { showModal && <EditPostpopup setItem={ setItem } setShowCreatePost={ setShowModal } item={ item } windowDimensions={ undefined } headerHeight={ undefined } showModal={ undefined } reload={ undefined } setShowDetails={ undefined }
            /> }
        </>
    );
}


const RenderImages = ( { images, imageInputRef, setImages, videos, setvideos } ) =>
{
    return (
        <div className='add_pics_inside1 p0'>
            <div className='preview_actions'>
                <AVAdd addEvent={ () => imageInputRef.current.click() } />
            </div>
                <div className='small_white_circle' onClick={ () => { setImages( [] ); setvideos( [] ); } }>
                    <AVCancel callback={ () => { setImages( [] ); setvideos( [] ); } } color={ '#000000' } size={ undefined } />
                </div>
            <div
                className={
                    images.length === 1
                        ? 'preview1'
                        : images.length === 2
                            ? 'preview2'
                            : images.length === 3
                                ? 'preview3'
                                : images.length === 4
                                    ? 'preview4 '
                                    : images.length === 5
                                        ? 'preview5'
                                        : images.length % 2 === 0
                                            ? 'preview6'
                                            : 'preview6 singular_grid'
                }
            >
                { videos?.map( ( img, i ) => (
                    <video
                        controls
                        width={ '100%' }
                        height={ 'auto' }
                        src={ img }
                        key={ i }
                    />
                ) ) }
                { images.map( ( img, i ) => (
                    <img src={ img } key={ i } alt='' className='Image' />
                ) ) }
            </div>
        </div>
    );
};

const PostHeader = ( {
    item,
    setItem,
    handleEditPopup,
    showEditPopup,
    handleDeletePost,
    handleEditPost,
    handleLikePost,
    menuRef,
    showModal,
    setShowModal,
    currentUser,
    handleremoveLike,
    handleDeleteComment,
} ) =>
{
    let uri = AVlink.getTummb( item?.person );

    if ( item?.person?.id === AV.Person.self().id )
    {
        let link = AV.Person.getLink( AV.Person.self() );
        uri = AVlink.getTummb( link );
    }

    return (
        <>
            <AHStack padding={ '12px' } justifyContent={ 'space-between' } width={ '100%' }>
                <AHStack>
                    <Link
                        to='/'
                        style={ {
                            backgroundImage: `url(${ fallBackImage })`,
                            backgroundRepeat: 'no-repeat',
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                        } }
                    >
                        { uri && (
                            <img
                                src={ uri }
                                style={ { width: 50, height: 50, borderRadius: '50%', objectFit: 'cover' } }
                                alt=''
                            />
                        ) }
                        { !uri && (
                            <Box bgColor='AVColor.grey' width={ '47px' } height={ '47px' } rounded='full'>
                                <img src={ LogoSvg } style={ { width: '100%', height: '100%', borderRadius: '50%' } } />{ ' ' }
                            </Box>
                        ) }
                    </Link>
                    <AVStack justifyContent={ 'center' } marginLeft={ 8 }>
                        <AHStack justifyContent={ 'space-between' }>
                            <Text fontSize={ [ 16 ] } fontWeight={ [ 'bold' ] }>
                                { item?.person?.title }
                            </Text>
                            <AVStack alignItems={ 'flex-start' } justifyContent={ 'flex-start' }>
                                <Pressable
                                    onPress={ () =>
                                    {
                                        handleEditPopup( item );
                                    } }
                                >
                                    <MoreHorizontalIcon />
                                </Pressable>
                            </AVStack>
                        </AHStack>
                        <Text fontSize={ [ 12, 14 ] }>
                            <Moment element={ Text } format='MMMM D, Y'>
                                { item?.lastUpdated }
                            </Moment>
                        </Text>
                    </AVStack>
                </AHStack>
            </AHStack>
            { showEditPopup && (
                <VStack
                    ref={ menuRef }
                    position={ 'absolute' }
                    rounded='lg'
                    right={ 1 }
                    top={ '26px' }
                    width='125px'
                    height='70px'
                    bgColor='#fff'
                    borderColor='coolGray.200'
                    borderWidth='1'
                    _dark={ {
                        borderColor: 'coolGray.600',
                    } }
                    shadow={ 3 }
                >
                    <Pressable
                        onPress={ () =>
                        {
                            handleEditPost( item );
                        } }
                    >
                        <HStack bottom={ 1 }>
                            <EditIcon size='md' marginTop={ 3 } marginLeft={ 3 } />
                            <Text margin={ '5px' } marginTop={ 3 } marginLeft={ 5 }>
                                { Loc.currentLang.menu.edit }
                            </Text>
                        </HStack>
                    </Pressable>
                    <Divider my='1' _light={ { bg: 'gray.100' } } top={ -10 } />
                    <Pressable
                        width={ '100%' }
                        onPress={ () =>
                        {
                            handleDeleteComment(item);
                        } }
                    >
                        <HStack bottom={ 5 }>
                            <DeleteIcon size='md' marginTop={ 3 } marginLeft={ 3 } />
                            <Text margin={ '5px' } marginTop={ 3 } marginLeft={ 5 }>
                                { Loc.currentLang.menu.delete }
                            </Text>
                        </HStack>
                    </Pressable>
                </VStack>
            ) }
            { showModal && <EditPostpopup setItem={ setItem } setShowCreatePost={ setShowModal } item={ item } windowDimensions={ undefined } headerHeight={ undefined } showModal={ undefined } reload={ undefined } setShowDetails={ undefined } /> }
        </>
    );
};
const PostActions = ( { item, handleLikePost, handleReplyPost } ) =>
{
    return (
        <AHStack gap={ 10 } alignItems={ 'center' } paddingHorizontal={ 10 } paddingVertical={ 5 }>
            <Pressable onPress={ () => handleLikePost( item, 'Like' ) }>
                <HStack alignItems={ 'center' } space={ 1 }>
                    <Text fontWeight={ 'bold' } fontSize={ '16px' } mt={ '1px' }>
                        { Loc.currentLang.menu.like }
                    </Text>
                    <LikeIcon size={ '20px' } color='gray.400' />
                </HStack>
            </Pressable>
            <Pressable onPress={ handleReplyPost }>
                <HStack alignItems={ 'center' } space={ 1 }>
                    <Text fontWeight={ 'bold' } fontSize={ '16px' } mt={ '1px' }>
                        { Loc.currentLang.menu.reply }
                    </Text>
                    <ReplyIcon size={ '20px' } />
                </HStack>
            </Pressable>
            <AHStack gap={ 3 } justifyContent={ 'flex-end' } flex={ 1 } paddingLeft={ 5 }>
                { item?.impressions.filter( ( x ) => x.impression == 'startruck' ).length > 0 && (
                    <AHStack
                        gap={ 3 }
                        borderRadius={ '20px' }
                        alignItems={ 'center' }
                        backgroundColor={ '#def2ff' }
                        paddingHorizontal={ '5px' }
                        paddingVertical={ '2px' }
                    >
                        <Image source={ { uri: startruck } } alt='startruck' size='18' />
                        <Text fontSize={ 11 } fontWeight={ 'bold' }>
                            { item?.impressions.filter( ( x ) => x.impression == 'startruck' ).length }
                        </Text>
                    </AHStack>
                ) }
                
                { item?.impressions.filter( ( x ) => x.impression == 'Like' ).length > 0 && (
                    <AHStack
                        gap={ 3 }
                        borderRadius={ '20px' }
                        alignSelf={ 'flex-end' }
                        backgroundColor={ '#def2ff' }
                        paddingHorizontal={ '5px' }
                        paddingVertical={ '2px' }
                    >
                        <Image source={ { uri: like } } alt='Like' size='18' />
                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                            { item?.impressions.filter( ( x ) => x.impression == 'Like' ).length }
                        </Text>
                    </AHStack>
                ) }
                { item?.impressions.filter( ( x ) => x.impression == 'fan' ).length > 0 && (
                    <AHStack
                        gap={ 3 }
                        borderRadius={ '20px' }
                        alignItems={ 'center' }
                        backgroundColor={ '#def2ff' }
                        paddingHorizontal={ '5px' }
                        paddingVertical={ '2px' }
                    >
                        <Image source={ { uri: fan } } alt='sad' size='18' />
                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                            { item?.impressions.filter( ( x ) => x.impression == 'fan' ).length }
                        </Text>
                    </AHStack>
                ) }
                
                { item?.impressions.filter( ( x ) => x.impression == 'sads' ).length > 0 && (
                    <AHStack
                        gap={ 3 }
                        borderRadius={ '20px' }
                        alignItems={ 'center' }
                        backgroundColor={ '#def2ff' }
                        paddingHorizontal={ '5px' }
                        paddingVertical={ '2px' }
                    >
                        <Image source={ { uri: sads } } alt='sads' size='18' />
                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                            { item?.impressions.filter( ( x ) => x.impression == 'sads' ).length }
                        </Text>
                    </AHStack>
                ) }
                
            </AHStack>
        </AHStack>
    );
};

const LoadMedia = ( { item, isDetail = false, showdetail } ) =>
{
    const [ imageUrls, setImagesUrl ] = React.useState( [] );
    const [ videoUrls, setVideoUrl ] = React.useState( [] );

    let videomedia : any = [];
    let imagemedia : any = [];
    React.useEffect( () =>
    {
        setImagesUrl( [] );
        setVideoUrl( [] );
        if (
            ( item?.galleryPost &&
                item?.galleryPost?.length > 0 &&
                item?.media?.type?.startsWith( 'image' ) ) ||
            ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
        )
        {
            for ( let i = 0; i < item?.galleryPost?.length; i++ )
            {
                imagemedia.push( item?.galleryPost[ i ].media );
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
            {
                imagemedia.push( item?.media );
                let images = imagemedia?.length && imagemedia.map( ( i ) => AV.Media.getURL( i ) );
                setImagesUrl( images );
            }
        } else
        {
            for ( var j = 0; j < item?.galleryPost?.length; j++ )
            {
                if (
                    item?.galleryPost[ j ]?.media?.storeId &&
                    item?.galleryPost[ j ]?.media?.type?.startsWith( 'video' )
                )
                {
                    videomedia.push( item?.galleryPost[ j ]?.media );
                    setVideoUrl( videomedia );
                } else
                {
                    imagemedia.push( item?.galleryPost[ j ]?.media );
                    let images = imagemedia?.length && imagemedia.map( ( i ) => AV.Media.getURL( i ) );
                    setImagesUrl( images );
                }
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'video' ) )
            {
                videomedia.push( item?.media );
                setVideoUrl( videomedia );
            }
        }
    }, [ item ] );

    return (
        <AVStack width={ '100%' }>
            <div>
                { imageUrls && imageUrls.length > 0 && (
                    <div
                        className={
                            showdetail
                                ? 'comment_img_grid_1'
                                : imageUrls.length === 1
                                    ? 'grid_1'
                                    : imageUrls.length === 2
                                        ? 'grid_2'
                                        : imageUrls.length === 3
                                            ? 'grid_3'
                                            : imageUrls.length === 4
                                                ? 'grid_4'
                                                : imageUrls.length >= 5
                                                    ? 'grid_5'
                                                    : undefined // or remove this line if you don't want to provide a class for other cases
                        }
                    >
                        { imageUrls.slice( 0, 5 ).map( ( url, i ) => (
                            <img
                                src={ url }
                                key={ `${ url }_${ i }` }
                                alt=''
                                className={ `img-${ i }` }
                                style={ { margin: '1px', objectFit: 'cover' } }
                            />
                        ) ) }
                        { imageUrls.length > 5 && (
                            <div className='more-pics-shadow'>+{ imageUrls.length - 5 }</div>
                        ) }
                    </div>
                ) }
            </div>
            <div>
                { videoUrls && videoUrls.length > 0 && (
                    <div
                        className={
                            videoUrls.length === 1
                                ? 'grid_1'
                                : videoUrls.length === 2
                                    ? 'grid_2'
                                    : videoUrls.length === 3
                                        ? 'preview3'
                                        : videoUrls.length === 4
                                            ? 'grid_4'
                                            : videoUrls.length >= 5
                                                ? 'grid_5'
                                                : undefined // or remove this line if you don't want to provide a class for other cases
                        }
                    >
                        { videoUrls.slice( 0, 5 ).map( ( vid, i ) => (
                             <Box key={ i }>
                                <HStack position={ 'absolute' } top={ '45%' } left={ '45%' }>
                                    <VideoIcon size={ '60px' } color='white'/>
                                </HStack>

                                <img
                                    src={ Media.getVideoPicURL( vid ) }
                                    alt=''
                                    className={ `img-${ i }` } // Apply 'processing' class when condition is true
                                    style={ { margin: '1px', objectFit: 'cover' } }
                                />
                            </Box>
                        ) ) }

                        { videoUrls.length > 5 && (
                            <div className='more-pics-shadow'>+{ videoUrls.length - 5 }</div>
                        ) }
                    </div>
                ) }
            </div>
        </AVStack>
    );
};

export default Comment;
